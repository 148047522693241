<template>
  <div class="flex flex-col items-center text-center justify-center h-screen">
    <div>
      <div class="text-base text-6xl md:text-6xl lg:text-8xl xl:text-8xl title">
        <span class="first-name">MATEA</span><span class="last-name">DJOJIC</span>
      </div>
      <div class="text-base text-4xl md:text-4xl lg:text-6xl xl:text-6xl subtitle">
        FRONTEND DEVELOPER
      </div>
    </div>
    <div class="text-center items-center pt-30 md:pt-20">
      <span class="text-base text-underline coming-soon text-2xl md:text-2xl lg:text-4xl xl:text-4xl">coming soon</span>
    </div>
    <div class="text-center items-center pt-20 md:pt-20">
      <span class="text-base text-underline coming-soon text-2xl md:text-2xl lg:text-2xl xl:text-2xl">
        <a href="mailto:me@mateadjojic.me">me[at]mateadjojic.me</a>
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>
<style scoped lang="scss">
.first-name {
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
}
.last-name {
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
}
.coming-soon {
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
  text-decoration: underline;
}
</style>
